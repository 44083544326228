
import { defineComponent, ref, onMounted } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ExportModal from "@/components/modals/forms/ExportModal.vue";
import StorePromotionModal from "@/components/store-promotions/forms/StorePromotionModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { getPromotions, updatePromotion } from "@/core/data/StorePromotion";
import { IStorePromotion } from "@/core/data/StorePromotion";
import $ from "jquery";
import moment from "moment/moment";

export default defineComponent({
  name: "store-promotion-listing",
  components: {
    // Table,
    // KTDatatable,
    ExportModal,
    StorePromotionModal,
    // UpdateStorePromotionModal,
  },
  setup() {
    const checkedCustomers = ref([]);
    const parentBorder = ref(false);
    const childBorder = ref(false);
    const tableData = ref<Array<IStorePromotion>>([]);
    const metadata = ref();
    const storePromotion = ref({});
    const idStorePromotion = ref(-1);
    const initStorePromotion = ref<Array<IStorePromotion>>([]);
    const search = ref<string>("");
    const baseOldCmsUrl = ref(process.env.VUE_APP_OLD_CMS_URL);

    const renderList = (name = "") => {
      getPromotions(0, 9999, true, name)
        .then((response) => {
          tableData.value = response.data.data;
          metadata.value = response.data.metadata;
          if (tableData.value.length > 0) {
            initStorePromotion.value.splice(
              0,
              tableData.value.length,
              ...tableData.value
            );
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    renderList();

    onMounted(async () => {
      setCurrentPageBreadcrumbs(
        "Danh sách chương trình ưu đãi gian hàng thành viên",
        ["Apps", "Chương trình marketing"]
      );

      // const response = await getPromotions()
      // tableData.value = response.data.data
    });
    document.addEventListener("mouseup", mouseUp);
    var timer;

    function mouseUp(e) {
      var container = $(".btn-action");

      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $("table td").css({ "z-index": 0 });
        clearTimeout(timer);
        timer = setTimeout(function () {
          // do something
          $(".kt_ecommerce_table table th:last-child").css(
            "position",
            "relative"
          );
          $(".kt_ecommerce_table table td:last-child").css(
            "position",
            "relative"
          );
        }, 10000);
      }
    }

    const handleScrollTable = (event) => {
      let element = event.target;
      var documentScrollLeft = element.scrollLeft;
      if (documentScrollLeft != 0) {
        element.querySelector("th:last-child").style.cssText = `
        visibility: visible;
        position: 'sticky;
      `;
        let tdElement = element.querySelectorAll("td:last-child");
        tdElement.forEach((element) => {
          element.style.cssText = `
        visibility: visible;
        position: 'sticky;
      `;
        });
      }
    };

    // const deleteFewCustomers = () => {
    //   checkedCustomers.value.forEach((item) => {
    //     handleDeleteBtn(item);
    //   });
    //   checkedCustomers.value.length = 0;
    // };

    const handleClickUpdateBtn = (id) => {
      idStorePromotion.value = id;
      // this.$refs.childComponent.sh
    };

    const handleDeleteBtn = (id) => {
      if (confirm("Bạn có chắc muốn xóa?"))
        updatePromotion(id, { state: "BLOCK" })
          .then(({ data }) => {
            Swal.fire({
              text: "Thành công!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              renderList();
              // window.location.reload()
            });
          })
          .catch(({ response }) => {
            console.log(response);
            Swal.fire({
              // text: "Xin lỗi, có vẻ như đã phát hiện thấy một số lỗi, vui lòng thử lại.",
              text: response.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          });
      // for (let i = 0; i < tableData.value.length; i++) {
      //   if (tableData.value[i].id === id) {
      //     tableData.value.splice(i, 1);
      //   }
      // }
    };

    const searchItems = () => {
      setTimeout(() => {
        if (search.value !== "") {
          renderList(search.value);
        } else {
          renderList();
        }
      }, 500);

      // tableData.value.splice(0, tableData.value.length, ...initStorePromotion.value);
      // if (search.value !== "") {
      //   let results: Array<IStorePromotion> = [];
      //   for (let j = 0; j < tableData.value.length; j++) {
      //     if (searchingFunc(tableData.value[j], search.value)) {
      //       results.push(tableData.value[j]);
      //     }
      //   }
      //   tableData.value.splice(0, tableData.value.length, ...results);
      // }
    };
    //
    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (
          !Number.isInteger(obj[key]) &&
          !(typeof obj[key] === "object") &&
          !(typeof obj[key] === "boolean")
        ) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      baseOldCmsUrl,
      metadata,
      tableData,
      handleDeleteBtn,
      search,
      searchItems,
      checkedCustomers,
      // deleteFewCustomers,
      handleClickUpdateBtn,
      handleScrollTable,
      storePromotion,
      idStorePromotion,
      renderList,
      parentBorder,
      childBorder,
    };
  },
  methods: {
    getState(startDate, endDate) {
      let now = new Date().getTime();
      if (now >= endDate) return "EXPIRED";
      else {
        let diff = startDate - now;
        if (diff <= 0) return "HAPPENING";
        else if (diff <= 259200 && diff >= 0) return "UPCOMING";
        return "REGISTERED";
      }
    },

    // convertIntToDateTime(value) {
    //   return value[2] + '-' + value[1] + '-' + value[0]
    // },
    convertIntToDateTime(value) {
      let timestamp = value / 1000;
      return moment.unix(timestamp).format("DD-MM-YYYY HH:mm:ss");
    },
  },
});
