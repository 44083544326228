
import { computed, defineComponent, ref, watch, reactive } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  createPromotion,
  getPackages,
  getPromotion,
  IStorePromotion,
  updatePromotion,
} from "@/core/data/StorePromotion";
import moment from "moment";

export default defineComponent({
  name: "StorePromotionFormModal",
  components: {},
  props: {
    idStorePromotion: { type: Number, require: true },
    renderList: { type: Function, require: true },
  },
  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const ModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const formData = ref<IStorePromotion>({
      code: "",
      name: "",
      packageId: 1,
      numberMonth: null,
      countVoucher: null,
      discountRate: null,
      discountPrice: 0,
      isMain: true,
      promotionId: null,
      startDate: "",
      endDate: "",
      state: "ACTIVE",
      packages: [],
    });
    const initFormData = ref({
      code: "",
      name: "",
      packageId: 1,
      numberMonth: null,
      countVoucher: null,
      discountRate: null,
      discountPrice: 0,
      isMain: true,
      promotionId: null,
      startDate: "",
      endDate: "",
      state: "ACTIVE",
      packages: [],
      // packages: [{
      //   id:2, numberMonth: 6, countVoucher:50
      // }]
      // packages: [
      //   '2,6'
      // ]
    });

    const getFormTitle = computed(() => {
      return action.value == "create"
        ? "Tạo chương trình tổng"
        : "Tạo chương trình con";
    });

    const getSubmitLabel = computed(() => {
      return action.value == "create" ? "Khởi tạo" : "Khởi tạo";
    });

    const action = ref<string>("create");
    const startTime = ref(new Date(2016, 9, 10, 18, 30));
    const endTime = ref(new Date(2016, 9, 10, 18, 30));
    const packages = ref([]);
    const disabledDate = (time: Date) => {
      return time.getTime() < Date.now() - 86400000;
    };
    const renderParentPackage = () => {
      getPackages()
          .then((response) => {
            let data = response.data.data;
            data.map(function (item, index) {
              item.children = [];
              if (item.name != "FREE") {
                item.children = [
                  {
                    id: item.id,
                    name: item.name + " 6 tháng",
                    numberMonth: 6,
                    value: item.id + "," + 6,
                  },
                  {
                    id: item.id,
                    name: item.name + " 12 tháng",
                    numberMonth: 12,
                    value: item.id + "," + 12,
                  },
                ];
              }
            });
            // data.push()
            packages.value = response.data.data;
            // metadata.value = response.data.metadata
          })
          .catch((e) => {
            console.log(e);
          });
    }
    renderParentPackage()
    watch(
      () => props.idStorePromotion,
      (selection, prevSelection) => {
        if (props.idStorePromotion != undefined && props.idStorePromotion > 0) {
          // action.value = "update";
          action.value = "createChild";
          formData.value.isMain = false;
          formData.value.promotionId = props.idStorePromotion;
        } else {
          formData.value = initFormData.value;
          action.value = "create";
        }
        if(action.value == 'createChild'){
          getPromotion(props.idStorePromotion)
              .then((response) => {
                response.data.package = {
                  id: 2,
                  numberMonth: 6,
                  value: 2 + "," + 6,
                };
                // formData.value = response.data
                let packageData = response.data.packages;
                packageData.map(function (item, index) {
                  packageData[index].value = item.id + "," + item.numberMonth;
                });
                packages.value = packageData;
              })
              .catch((e) => {
                console.log(e);
              });
        }
        else{
          renderParentPackage()
        }
      }
    );

    const rules = ref({
      name: [
        {
          required: true,
          message: "Tên chương trình không được bỏ trống",
        },
      ],
      code: [
        {
          required: true,
          message: "Ký hiệu mã ưu đãi không được bỏ trống",
        },
      ],
      packageId: [
        {
          required: true,
          message: "Gói dịch vụ áp dụng không được bỏ trống",
        },
        { type: "number", message: "Phải là số" },
      ],
      countVoucher: [
        {
          required: true,
          message: "Số lượng voucher không được bỏ trống",
        },
        { type: "number", message: "Phải là số" },
      ],
      discountRate: [
        {
          required: true,
          message: "Tỉ lệ giảm giá không được bỏ trống",
        },
        { type: "number", message: "Phải là số" },
      ],
      // discountPrice: [
      //   {
      //     required: true,
      //     message: "Giảm giá, không được bỏ trống",
      //
      //   },
      //   {type: 'number', message: 'Phải là số'},
      // ],
      startDate: [
        {
          required: true,
          message: "Ngày bắt đầu không được bỏ trống",
        },
      ],
      endDate: [
        {
          required: true,
          message: "Ngày kết thức không được bỏ trống",
        },
      ],
    });
    const handleSelectChange = (item) => {
      formData.value.packageId = item.id;
      formData.value.numberMonth = item.numberMonth;
    };

    const handleTimeChange = (e) => {
      console.log(e);
      console.log(formData.value.startDate);
    };

    const handleSelectCheckbox = (e, item) => {
      var index = formData.value.packages.findIndex(
        (x) => x.id == item.id && x.numberMonth == item.numberMonth
      );
      if (e.target.checked) {
        const objs = formData.value.packages.slice();
        var quantity = parseInt(
          e.target.parentNode.parentNode.parentNode.querySelector(
            "#countVoucher"
          ).value
        );
        var countVoucher = 0;
        if (quantity > 0) countVoucher = quantity;
        if (index > -1) {
          formData.value.packages = [
            ...objs.slice(0, index),
            {
              ...objs[index],
              countVoucher: countVoucher,
            },
            ...objs.slice(index + 1),
          ];
        } else {
          item.countVoucher = countVoucher;
          formData.value.packages.push(item);
        }
      } else {
        if (index > -1) {
          formData.value.packages.splice(index, 1);
        }
      }
    };
    const handleChangeQuantity = (e, item) => {
      var index = formData.value.packages.findIndex(
        (x) => x.id == item.id && x.numberMonth == item.numberMonth
      );
      if (e.target.value.length > 0) {
        const objs = formData.value.packages.slice();
        if (index > -1) {
          formData.value.packages = [
            ...objs.slice(0, index),
            {
              ...objs[index],
              countVoucher: e.target.value,
            },
            ...objs.slice(index + 1),
          ];
        } else {
          item.countVoucher = e.target.value;
          formData.value.packages.push(item);
        }
      } else {
        if (index > -1) {
          formData.value.packages.splice(index, 1);
        }
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          setTimeout(() => {
            loading.value = false;
            let func =
              action.value == "update"
                ? updatePromotion(props.idStorePromotion, formData.value)
                : createPromotion(formData.value);
            func
              .then(({ data }) => {
                Swal.fire({
                  text: "Thành công!",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  if (props.renderList != undefined) props.renderList();
                  // window.location.reload()
                  hideModal(ModalRef.value);
                });
              })
              .catch(({ response }) => {
                console.log(response);
                Swal.fire({
                  // text: "Xin lỗi, có vẻ như đã phát hiện thấy một số lỗi, vui lòng thử lại.",
                  text: response.data.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
                return false;
              });
          }, 2000);
        } else {
          Swal.fire({
            text: "Xin lỗi, có vẻ như đã phát hiện thấy một số lỗi, vui lòng thử lại.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };
    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      ModalRef,
      packages,
      disabledDate,
      action,
      getFormTitle,
      getSubmitLabel,
      handleSelectChange,
      handleChangeQuantity,
      handleSelectCheckbox,
      startTime,
      endTime,
      handleTimeChange,
    };
  },
});
